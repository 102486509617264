/*=========================================================================================
  File Name: moduleCompetitionActions.js
  Description: Competition Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  GetTopWeeklyVideos() {
    return new Promise((resolve, reject) => {
      axios.post("api/Video/GetTopVideosInWeek")
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  GetTopMonthlyVideos() {
    return new Promise((resolve, reject) => {
      axios.post("api/Video/GetTopVideosInMonth")
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  GetAllDataStatistics() {
    return new Promise((resolve, reject) => {
      axios.get("api/Home/GetAllDataStatistics")
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  GetLastCompetitios() {
    return new Promise((resolve, reject) => {
      axios.get("api/Home/GetLastCompetitios")
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
