<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  
 <div   class="home"> 
  
    <e-charts autoresize :options="option" ref="bar" auto-resize />
    <div class="vx-row justify-center">
      <span class="text-xl text-primary mr-2 ml-2">{{ $t("Total") }} </span>
      <span class="text-xl text-primary mr-2 ml-2">
        {{ total }}
      </span>
      <div class="vx-col" v-for="(item, index) in AllUsers" :key="index">
        <span>{{ $t(item.Key)  }}</span>
        <span class="text-xl mr-2 ml-2">{{ item.Value }}</span>
      </div>
    </div>
    <h1 class="mt-5" style="text-align:center;color:red">{{$t('WinnersVideosLastCompetition')}}</h1>

    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/2 sm:w-1/2 mb-base mt-5"
        v-for="item in Competitions"
        :key="item.ID"
        
      >
        <vx-card style="background-color:#779eed" v-if="item.IsWeekly" class="vxCardPatient text-center cursor-pointer rounded-xl">
          <h3 style="color:white;text-align:center">{{$t('Weekly')}}</h3> 
          <div class="vx-row m-3" style="justify-content: center">
          <h2>  {{$t('Date')}} :  {{ item.WeeklyDate }}</h2>
          </div>
          <div class="vx-col">
            <video
              :width="350"
              :height="200"
              id="videojs-sprite-thumbnails-player"
              class="video-js vjs-default-skin"
              data-setup='{"aspectRatio":"12:5"}'
              controls
            >
              <source :src="baseURL + item.WeeklyVideo.URL" type="video/mp4" />
              <!-- <source src="//vjs.zencdn.net/v/oceans.webm" type='video/webm'> -->
            </video>
            <div class="vx-col mb-3 mt-3">
              <h4 class="mb-2">
                {{$t('User')}} :  {{ item.WeeklyVideo.AppUser.ApplicationUser.FullName }}
              </h4>
              <h4 class="mb-2">
              {{$t('TotalRate')}} :  {{ item.TotalRate}}
              </h4>
              <u style="size:20px" class="mb-2" @click="GotoVideoDetails(item.WeeklyVideoID)">
              {{$t('GotoVideoDetails')}}
              </u>
            </div>
          </div>

        </vx-card>



        <vx-card v-else style="background-color:#3e9c72"  class="vxCardPatient text-center cursor-pointer rounded-xl">
          <h3 style="color:white;text-align:center">  {{$t('Monthly')}}</h3>  
          <div class="vx-row m-3" style="justify-content: center">
           <h2> {{$t('Date')}} :  {{ item.MonthlyDate }}</h2>
          </div>
          <div class="vx-col">
            <video
              :width="350"
              :height="200"
              id="videojs-sprite-thumbnails-player"
              class="video-js vjs-default-skin"
              data-setup='{"aspectRatio":"12:5"}'
              controls
            >
              <source :src="baseURL + item.MonthlyVideo.URL" type="video/mp4" />
              <!-- <source src="//vjs.zencdn.net/v/oceans.webm" type='video/webm'> -->
            </video>

            <div class="vx-col mb-3 mt-3">
              <h4 class="mb-2">
              {{$t('User')}} :  {{ item.MonthlyVideo.AppUser.ApplicationUser.FullName }}
              </h4>
              <h4 class="mb-2">
              {{$t('TotalRate')}} :  {{ item.TotalRate}}
              </h4>
              <u style="size:20px" class="mb-2" @click="GotoVideoDetails(item.MonthlyVideoID)">
              {{$t('GotoVideoDetails')}}
              </u>
            </div>
          </div>

        </vx-card>
      </div>
    </div>
<div id="data-list-list-view"  class="vx-row">
  <div
        class="vx-col w-full md:w-1/2 sm:w-1/2 mb-base mt-5">
       <h3 class="mt-5" style="text-align:center;color:red">{{$t('TopWeeklyVideo')}}</h3>
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="TopWeeklyVideos"
        >
          <template slot="thead">
            <vs-th> </vs-th>
            <vs-th> {{ $t("User") }}</vs-th>
     
            <vs-th> {{ $t("TotalRate") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <video
      v-if="tr.URL"
              :width="50"
              :height="50"
              id="videojs-sprite-thumbnails-player"
              class="video-js vjs-default-skin"
              data-setup='{"aspectRatio":"12:5"}'
              controls
            >
              <source :src="baseURL + tr.URL" type="video/mp4" />
              <!-- <source src="//vjs.zencdn.net/v/oceans.webm" type='video/webm'> -->
            </video>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.AppUser.ApplicationUser.FullName }}
                  </p>
                </vs-td>
             
                <vs-td class="whitespace-no-wrap">
                  <p class="product-name font-medium truncate">
                    {{ tr.TotalRateValue }}
                  </p>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
    </div>
    <div
        class="vx-col w-full md:w-1/2 sm:w-1/2 mb-base mt-5">
       <h3 class="mt-5" style="text-align:center;color:red">{{$t('TopMonthlyVideo')}}</h3>

        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="TopMonthlyVideos"
        >
          <template slot="thead">
            <vs-th> </vs-th>
            <vs-th> {{ $t("User") }}</vs-th>
     
            <vs-th> {{ $t("TotalRate") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <video
      v-if="tr.URL"
              :width="50"
              :height="50"
              id="videojs-sprite-thumbnails-player"
              class="video-js vjs-default-skin"
              data-setup='{"aspectRatio":"12:5"}'
              controls
            >
              <source :src="baseURL + tr.URL" type="video/mp4" />
              <!-- <source src="//vjs.zencdn.net/v/oceans.webm" type='video/webm'> -->
            </video>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.AppUser.ApplicationUser.FullName }}
                  </p>
                </vs-td>
             
                <vs-td class="whitespace-no-wrap">
                  <p class="product-name font-medium truncate">
                    {{ tr.TotalRateValue }}
                  </p>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
    </div>
</div>
    
  </div>
</template>
<script>
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/component/tooltip";
import "echarts-gl";
import moduleHome from "@/store/Home/moduleHome.js";
import { domain } from "@/gloabelConstant.js";
export default {
  data() {
    return {
      TopWeeklyVideos:[],
      TopMonthlyVideos:[],
      baseURL: domain,
      total: 0,
      AllUsers: null,
      Competitions: null,
      option: {
        title: {
          text: this.$t("AllUsers"),
          left: this.$vs.rtl ? "right" : "left",
          textStyle: {
            fontFamily: "Cairo",
            color: this.$store.state.theme === "dark" ? "white" : "black"
          }
        },
        toolbox: {
          iconStyle: {
            borderColor: this.$store.state.theme === "dark" ? "white" : "black"
          },
          left: this.$vs.rtl ? "left" : "right",
          feature: {
            magicType: {
              show: true,
              title: this.$t("ChangeType"),
              type: ["line", "bar"]
            },
            restore: { show: true, title: this.$t("Reset") },
            saveAsImage: {
              title: this.$t("Save"),
              type: "png",

              name: "Save"
            }
          }
        },
        color: ["#9DA6D3"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",

            data: [],
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              rotate: 45,
              fontWeight: "bold",
              color: this.$store.state.theme === "dark" ? "white" : "black",
              fontSize: 10
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: this.$store.state.theme === "dark" ? "white" : "black"
            }
          }
        ],
        series: [
          {
            type: "bar",
            data: []
          }
        ]
      }
    };
  },
  methods: {
    GotoVideoDetails(videoID){
      this.$router.push({ name: 'Video_Edit', params:{ID:videoID} });
    },
    GetAllUsers() {
      this.option.series[0].data = [];
      this.option.xAxis[0].data = [];

      debugger;
      this.$store.dispatch("HomeList/GetAllDataStatistics").then(response => {
        this.AllUsers = response.data.data;

        response.data.data.forEach(element => {
          this.option.series[0].data.push(element.Value);
          this.option.xAxis[0].data.push(this.$t(element.Key));
        });
        response.data.data.forEach(element => {
          this.total += Number(element.Value);
        });
        return this.total;
      });
    },
    GetCompetitions() {
      debugger;
      this.$store.dispatch("HomeList/GetLastCompetitios").then(response => {
        this.Competitions = response.data.data;
      });
    },
    GetTopWeeklyVideos() {
      debugger;
      this.$store.dispatch("HomeList/GetTopWeeklyVideos").then(response => {
        this.TopWeeklyVideos = response.data.data;
      });
    },
    GetTopMonthlyVideos() {
      debugger;
      this.$store.dispatch("HomeList/GetTopMonthlyVideos").then(response => {
        this.TopMonthlyVideos = response.data.data;
      });
    },
  },
  computed: {
    
  },

  components: {
    ECharts,
  },
  created() {
    if (!moduleHome.isRegistered) {
      this.$store.registerModule("HomeList", moduleHome);
      moduleHome.isRegistered = true;
    }
  this.GetAllUsers();
  this.GetCompetitions();
  this.GetTopWeeklyVideos();
  this.GetTopMonthlyVideos();
  },
  beforeDestroy() {
    // this.StopInterval();
  }
};
</script>

<style lang="scss">
   .home h2 ,h3,h4,u{
   color: white;
   size: 50px;
  }
  #data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
